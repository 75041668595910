<template>
  <div class="flex flex-col gap-4">
    <section id="header" class="flex justify-center">
        <span class="font-bold text-2xl">
          Información Juzgado
        </span>
    </section>

    <section id="body" class="flex flex-col items-center gap-2">
      <div class="flex flex-col w-full lg:w-2/4">
        <span class="text-xs">Nombre del juzgado:</span>
        <AutoComplete v-model="model.nombre"
                      :suggestions="filteredJuzgados"
                      @complete="searchJuzgados($event)"
                      @item-select="changeJuzgados($event)"
                      field="nombre"
                      inputClass="w-full"
        />
        <MessageError :text="errors.nombre" />
      </div>
      <div class="flex flex-col w-full lg:w-2/4">
        <span class="text-xs">Tipo de Juzgado:</span>
        <Dropdown v-model="model.categoriaId" :options="juzgadoCategorias" optionLabel="nombre" optionValue="id" :filter="true"/>
        <MessageError :text="errors.categoriaId" />
      </div>
      <div class="flex flex-col w-full lg:w-2/4">
        <span class="text-xs">Numero de Juzgado:</span>
        <Dropdown v-model="model.code" :options="noJuzgados" optionLabel="nombre" optionValue="nombre" :filter="true"/>
        <MessageError :text="errors.code" />
      </div>
      <div class="flex flex-col w-full lg:w-2/4">
        <span class="text-xs">Departamento:</span>
        <Dropdown v-model="model.departamentoId" :options="departamentos" optionLabel="nomDpto" optionValue="codDpto" :filter="true" @change="onChangeDepartamentos($event)"/>
        <MessageError :text="errors.departamentoId" />
      </div>
      <div class="flex flex-col w-full lg:w-2/4">
        <span class="text-xs">Municipio:</span>
        <Dropdown v-model="model.municipioId" :options="municipios" optionLabel="nomMpio" optionValue="codMpio" :filter="true"/>
        <MessageError :text="errors.municipioId" />
      </div>
    </section>

    <section id="footerInfo" class="flex justify-center gap-4">
      <div class="flex justify-between w-full lg:w-2/4">
        <Button label="Atras"
                icon="pi pi-angle-left"
                class="p-button-danger"
                @click="backPage()"
        />
        <Button label="Siguiente"
                icon="pi pi-angle-right"
                iconPos="right"
                @click="nextPage()"
        />
      </div>
    </section>
  </div>
</template>

<script>
import ProcesosJudicialesStore from '../../../../../store/procesosJudiciales.store'
import JuzgadosStore from '../../../../../store/juzgado.store'
import LocalizacionStore from '../../../../../store/localizacion.store'
import JuzgadoCategoriaStore from '../../../../../store/juzgadoCategoria.store'
import { onActivated, computed, ref } from 'vue'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import { useRouter } from 'vue-router'
import { FilterMatchMode } from 'primevue/api'
import NoJuzgadosService from '@/apps/pharmasan/juridica/procesosJuridicos/services/noJuzgados.service'

export default {
  name: 'informacion-juzgado',
  setup () {
    const router = useRouter()
    const filteredJuzgados = computed(() => JuzgadosStore.getters.juzgados.rows)
    const departamentos = computed(() => LocalizacionStore.getters._departamentos)
    const municipios = computed(() => LocalizacionStore.getters._municipios)
    const juzgadoCategorias = computed(() => JuzgadoCategoriaStore.getters._juzgadoCategorias)
    const _NoJuzgadosService = new NoJuzgadosService()
    const noJuzgados = ref([])
    const juzgadoId = ref('')

    const schema = yup.object({
      nombre: yup.string().required('El campo es requerido').label(''),
      categoriaId: yup.string().required('El campo es requerido'),
      code: yup.string().required('El campo es requerido').label(''),
      departamentoId: yup.string().required('El campo es requerido'),
      municipioId: yup.string().nullable()
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('nombre')
    useField('categoriaId')
    useField('code')
    useField('departamentoId')
    useField('municipioId')

    const backPage = () => {
      router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.crear.buscar-accionante' })
    }
    const nextPage = handleSubmit((values) => {
      ProcesosJudicialesStore.dispatch('setNewProcesoJudicialJuzgado', {
        juzgadoId: juzgadoId.value,
        juzgado: values
      })
      router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.crear.detalle-proceso' })
    })

    const searchMunicipio = (codDpto) => {
      LocalizacionStore.dispatch('getMunicipios', codDpto)
    }

    const onChangeDepartamentos = (e) => {
      searchMunicipio(e.value)
      model.municipioId = ''
    }

    const searchJuzgados = ({ query }) => {
      JuzgadosStore.dispatch('getPaginate', {
        filters: {
          nombre: {
            value: query.toUpperCase(),
            matchMode: FilterMatchMode.CONTAINS
          }
        }
      })
    }

    const changeJuzgados = ({ value }) => {
      searchMunicipio(value.departamentoId)
      juzgadoId.value = value.id
      model.nombre = value.nombre
      model.categoriaId = value.categoriaId
      model.code = value.code
      model.departamentoId = value.departamentoId
      model.municipioId = value.municipioId
    }

    onActivated(() => {
      LocalizacionStore.dispatch('getDeparatamentos')
      JuzgadoCategoriaStore.dispatch('getJuzgadoCategorias')
      _NoJuzgadosService.paginate().then(({ data }) => {
        noJuzgados.value = data.rows
      })
    })

    return {
      errors,
      model,
      filteredJuzgados,
      nextPage,
      departamentos,
      municipios,
      noJuzgados,
      juzgadoCategorias,
      searchJuzgados,
      changeJuzgados,
      onChangeDepartamentos,
      backPage
    }
  }

}
</script>

<style scoped>

</style>
